:root {
	--MainColor: #e00404;
	--ButtonHoverColor: #ed5656;
	--blackColor: #1a1a1a;
	--whiteColor: #fcfcfc;
	--boxShadow: #b6b6b6;
	--sliderDot: #858585;

	/* Text Font Sizes */
	--MobileFontSize: 0.9rem;
	--MobileFontSizeSmall: 0.7rem;
	--TabletFontSize: 0.7rem;
	--TabletFontSizeSmall: 0.65rem;
	--PcFontSize: 1rem;
	--PcFontSizeSmall: 0.85rem;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	overflow-x: hidden;
	font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
	text-decoration: none;
}

h1,
h2,
h3,
h4 {
	font-weight: 700;
}

/* Selection Color */
::-moz-selection {
	/* Code for Firefox */
	color: var(--whiteColor);
	background: var(--MainColor);
}

::selection {
	color: var(--whiteColor);
	background: var(--MainColor);
}

/* React Slider */
.slick-dots {
	bottom: -60px !important;
}

.slick-dots li.slick-active button:before {
	color: var(--MainColor) !important;
	font-size: 1.4rem !important;
}

.slick-dots li button:before {
	color: var(--sliderDot) !important;
	font-size: 0.8rem !important;
}

@media (max-width: 600px) {
	.slick-dots {
		bottom: -60px;
	}
	.slick-dots li.slick-active button:before {
		font-size: 1.4rem;
	}
	.slick-dots li button:before {
		font-size: 0.7rem;
	}
}

@media (min-width: 601px) and (max-width: 1024px) {
	.slick-dots {
		bottom: -60px;
	}
	.slick-dots li.slick-active button:before {
		font-size: 1.2rem !important;
	}
	.slick-dots li button:before {
		font-size: 0.6rem !important;
	}
}

@media (min-width: 1900px) {
	.slick-dots {
		bottom: -80px;
	}
	.slick-dots li.slick-active button:before {
		font-size: 3rem;
	}
	.slick-dots li button:before {
		font-size: 2.5rem;
	}
	.slick-dots li {
		margin-left: 2rem;
	}
}
